// extracted by mini-css-extract-plugin
export var wrapper = "archive-module--wrapper--YD+m+";
export var filterWrapper = "archive-module--filterWrapper--CH-7J";
export var sticky = "archive-module--sticky--pIq38";
export var year = "archive-module--year--B5750";
export var accordion = "archive-module--accordion--s4n4C";
export var resultsWrapper = "archive-module--resultsWrapper--TyWYT";
export var root = "archive-module--root--+Xtgf";
export var yearResources = "archive-module--yearResources--pn0M0";
export var searchWrapper = "archive-module--searchWrapper--g21nZ";
export var yearFilter = "archive-module--yearFilter--PF4hy";
export var yearCheckFilter = "archive-module--yearCheckFilter--n7myu";